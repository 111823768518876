import React from 'react'
import Head from 'next/head'
import { useSelector } from 'react-redux'

import WelcomeBack from '../components/welcome-back'
import OrientationNotice from '../components/orientation-notice'
import { selectStart } from '../app/features/engine/engineSlice'

export default function Home ({ fullScreenHandle }) {
  const start = useSelector(selectStart)

  return (
    <>
      <Head>
        <title>Blip Con</title>
        <meta name='description' content='Blip Con' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      {!start && <WelcomeBack fullScreenHandle={fullScreenHandle} />}

      <OrientationNotice />
    </>
  )
}
