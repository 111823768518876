import { useRouter } from 'next/router'

export default function WelcomeBack ({ fullScreenHandle }) {
  const router = useRouter()

  async function goToIntroVideo () {
    try {
      await fullScreenHandle.enter()
    } catch (error) {
      // Ignore errors if we cannot enter fullscreen
      console.log(error)
    }

    await router.push('/intro-video')
  }

  return (
    <div className='center'>
      <div className='small box with-logo'>
        <p>Que bom te ver de novo!</p>
        <button onClick={goToIntroVideo}>Entrar</button>
        <button onClick={() => router.push('/videos')}>Ir Direto Ao Vivo</button>
      </div>
    </div>
  )
}
